import { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";

import { ValidatedServiceToken } from "../../types";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID
};

firebase.initializeApp(firebaseConfig);

const verifyToken = firebase
  .functions()
  .httpsCallable("event-validateTokenForService");

export default function useFirebaseAuth() {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [roomToken, setRoomToken] = useState<string | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);

  let authRes: (token: string) => void;
  const getToken = async (_roomId: string): Promise<string> => {
    if (roomToken) {
      return roomToken;
    }

    return new Promise(res => {
      authRes = res;
    });
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(newUser => {
      setUser(newUser);
      setIsAuthReady(true);
    });
  }, []);

  const signIn = async (token: string): Promise<boolean> => {
    return verifyToken({
      service: "video-chat",
      token
    }).then(async result => {
      const token = result.data as ValidatedServiceToken | false;
      if (!token) {
        console.log("failed to validate token");
        return false;
      }
      const loggedIn = await firebase
        .auth()
        .signInWithCustomToken(token.userToken)
        .catch(err => {
          console.log("error signing in with token", err);
          return false;
        });

      setRoomToken(token.data.roomToken);
      if (authRes) {
        authRes(token.data.roomToken);
      }
      if (loggedIn) {
        return true;
      } else {
        return false;
      }
    });
  };

  return { user, isAuthReady, getToken, signIn };
}
