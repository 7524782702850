import React, { createContext, useContext, useReducer, useState } from "react";
import { RoomType } from "../types";
import { TwilioError } from "twilio-video";
import {
  settingsReducer,
  initialSettings,
  Settings,
  SettingsAction
} from "./settings/settingsReducer";
import useActiveSinkId from "./useActiveSinkId/useActiveSinkId";
import useFirebaseAuth from "./useFirebaseAuth/useFirebaseAuth";
import firebase from "firebase";
import { param } from "../utils";

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  authToken: string;
  user?:
    | firebase.User
    | null
    | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn(token?: string): Promise<boolean>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  getToken: (roomId: string) => Promise<string>;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(
    settingsReducer,
    initialSettings
  );

  const authToken = param("token");

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    authToken,
    ...useFirebaseAuth()
  } as StateContextType;

  if (!contextValue.user) {
    if (!authToken) {
      throw new Error("Must be initialized with a valid token");
    }

    contextValue.signIn(authToken as string);
  }

  const getToken: StateContextType["getToken"] = room => {
    setIsFetching(true);
    return contextValue
      .getToken(room)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getToken }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
}
