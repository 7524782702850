import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { useAppState } from "../../state";

export default function PrivateRoute({ children, ...rest }: RouteProps) {
  const { isAuthReady, user } = useAppState();

  const renderChildren = user || !process.env.REACT_APP_SET_AUTH;

  if (!renderChildren && !isAuthReady) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={() => (renderChildren ? children : <p>Loading...</p>)}
    />
  );
}
