export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints["video"] = {
  width: 1280,
  height: 720,
  frameRate: 24
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = "revnt-videoChat-selectedAudioInput";
export const SELECTED_AUDIO_OUTPUT_KEY = "revnt-videoChat-selectedAudioOutput";
export const SELECTED_VIDEO_INPUT_KEY = "revnt-videoChat-selectedVideoInput";
